


































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { reduce } from 'lodash'

// components
import Tag from '@/components/_uikit/Tag.vue'
import UserCard from '@/components/cards/UserCard.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import {
  CourseType,
  ManagerOrderLargeResource,
  ManagerOrderStoreRequest, NameValueResource, OrderStatus,
  PackageShortResource,
  PurchaseGroupResource,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'
import ManagerOrdersModule from '@/store/modules/manager/orders'
import DictionaryModule from '@/store/modules/dictionary'

@Component({
  components: {
    Tag,
    UserCard,
  },
})
export default class ManagerOrderForm extends Mixins(NotifyMixin, PermissionsMixin) {
  @Prop({ required: true })
  private order!: ManagerOrderLargeResource

  private get isPackageTagLarge() {
    return this.$vuetify.breakpoint.name !== 'xs'
  }

  private get isSpecial() :boolean {
    return Boolean(this.order?.groups[0].course.type === CourseType.SPECIAL)
  }

  // Сумма стоимости заказа без скидок, промокодов и пакетных предложений (суммы месяцев заказа)
  private get orderOriginalPriceSum() {
    if (!this.order)
      return 0
    return this.order.packages.reduce((acc: number, cur: PackageShortResource) => acc + (cur.package ? cur.package.originalPrice : cur.originalPrice), 0)
  }

  private get orderStatusesPaidCase() {
    return DictionaryModule.orderStatuses.filter(item => item.value === OrderStatus.PAID || item.value === OrderStatus.REFUND)
  }

  private get orderStatusesNotPaidCase() {
    return DictionaryModule.orderStatuses.filter(item => item.value === OrderStatus.WAIT || item.value === OrderStatus.PAID || item.value === OrderStatus.CANCELED)
  }

  private get orderStatuses() {
    return DictionaryModule.orderStatuses
  }

  private get self () {
    return AuthModule.self
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private form: ManagerOrderStoreRequest = {
    isManual: false,
    managerId: null,
    packages: [],
    userId: 0,
  }

  private isMy = false
  private isOrderPaid = false
  private isRefundOrCancelled = false
  private isWaitingForPayment = false

  private packages: PackageShortResource[] = []
  private special = CourseType.SPECIAL

  private mounted() {
    this.syncForm()
    this.determinateOrderStatus()
  }

  private syncForm() {
    this.packages = reduce(this.order.groups, (packages: PackageShortResource[], item: PurchaseGroupResource) => {
      if (item.packages) {
        packages.push(...item.packages.map(pack => ({ ...pack, title: item.course.name })))
      }
      return packages
    }, [])

    this.form = {
      isManual: false,
      managerId: this.order.manager ? this.order.manager.id : null,
      packages: this.packages.map((item: PackageShortResource) => ({
        packageId: item.id,
        price: item.price,
      })),
      status: this.order.status.value as OrderStatus,
      userId: this.order.user.id,
    }

    this.isMy = !!(this.order.manager && this.order.manager.id === this.self?.id)
  }

  private handleSaveOrderStatus() {
    if (this.order) {
      ManagerOrdersModule.saveOrder({ orderID: this.order.id, params: this.form })
        .then(() => {
          this.notifySuccess(`Заказ # ${this.order?.id} обновлён`)
          // this.$emit('update-order', { orderId: this.order?.id, orderStatus: this.chosenOrderStatus || response.status })
        })
        .catch(this.notifyError)
    }
  }

  private handleChosenOrderStatus(orderStatus: NameValueResource) {
    this.form.status = orderStatus.value as OrderStatus
  }

  private handleIsMyChange(value: boolean) {
    this.form.managerId = value ? this.self?.id : null
  }

  private determinateOrderStatus() {
    switch((this.order as ManagerOrderLargeResource).status.value) {
    case OrderStatus.PAID:
      this.isOrderPaid = true
      break
    case OrderStatus.WAIT:
      this.isWaitingForPayment = true
      break
    case OrderStatus.REFUND:
    case OrderStatus.CANCELED:
      this.isRefundOrCancelled = true
      break
    }
  }

  private resetOrderStatus() {
    this.isOrderPaid = false
    this.isWaitingForPayment = false
    this.isRefundOrCancelled = false
  }
}
